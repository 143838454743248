exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-custom-index-js": () => import("./../../../src/pages/custom-index.js" /* webpackChunkName: "component---src-pages-custom-index-js" */),
  "component---src-pages-new-proverb-js": () => import("./../../../src/pages/new-proverb.js" /* webpackChunkName: "component---src-pages-new-proverb-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-templates-no-proverb-js": () => import("./../../../src/templates/no-proverb.js" /* webpackChunkName: "component---src-templates-no-proverb-js" */),
  "component---src-templates-owe-js": () => import("./../../../src/templates/owe.js" /* webpackChunkName: "component---src-templates-owe-js" */),
  "component---src-templates-owe-letter-js": () => import("./../../../src/templates/owe-letter.js" /* webpackChunkName: "component---src-templates-owe-letter-js" */)
}

